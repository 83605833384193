import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import awards from "../../images/cyberSecurity/awards-and-badges.svg"
import experience from "../../images/cyberSecurity/experience.svg"
import global from "../../images/cyberSecurity/global-offices.svg"
import iso from "../../images/cyberSecurity/iso-certified-developers.svg"
import reviews from "../../images/cyberSecurity/reviews.svg"
import successful from "../../images/cyberSecurity/successful-projects.svg"
import ViewableCountUp from "../../utils/ViewableCountUp"
import * as styles from "./InvozoneNumber.module.scss"

const cardsData = [
  {
    image: experience,
    title: 10,
    sign: `+`,
    description: `Years In the Industry`,
  },
  {
    image: iso,
    title: 500,
    sign: `+`,
    description: `Developers`,
  },
  {
    image: successful,
    title: 300,
    sign: `+`,
    description: `Successful Projects`,
  },
  {
    image: reviews,
    title: 5,
    sign: ``,
    description: `Star Clutch Review`,
  },
  {
    image: global,
    title: 4,
    sign: ``,
    description: `Global Offices`,
  },
  {
    image: awards,
    title: 20,
    sign: `+`,
    description: `Awards & Badges`,
  },
]

const InvozoneNumber = ({ strapiData }) => {
  return (
    <div className={styles.number}>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
        <div className={styles.cardsData}>
          <Row className="gap-30">
            {cardsData?.map((item, index) => (
              <Col lg={4} className="gap-30" key={index}>
                <div className={styles.cards}>
                  <img
                    className={styles.cardImg}
                    src={item?.image}
                    alt={item?.title}
                    decoding="async"
                    loading="lazy"
                  />
                  <div className="text-left">
                    <ViewableCountUp
                      start={0}
                      end={item?.title}
                      duration={5}
                      suffix={item?.sign}
                    />
                    <p>{item?.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default InvozoneNumber
