// extracted by mini-css-extract-plugin
export var Android = "StopAnonymous-module--Android--1ed9c";
export var Group = "StopAnonymous-module--Group--73ca4";
export var Vector = "StopAnonymous-module--Vector--2b515";
export var Vector1 = "StopAnonymous-module--Vector1--22153";
export var Vector2 = "StopAnonymous-module--Vector2--45084";
export var arrowImg = "StopAnonymous-module--arrowImg--bd72b";
export var bannerBtn = "StopAnonymous-module--bannerBtn--d5e80";
export var boxContent = "StopAnonymous-module--boxContent--9ca02";
export var btn_white1_border = "StopAnonymous-module--btn_white1_border--b3ad3";
export var cards = "StopAnonymous-module--cards--e6047";
export var cardsContent = "StopAnonymous-module--cardsContent--1e76e";
export var cardsZ = "StopAnonymous-module--cardsZ--7e9af";
export var description = "StopAnonymous-module--description--63cb0";
export var dots = "StopAnonymous-module--dots--14c78";
export var estabBtn = "StopAnonymous-module--estabBtn--871b1";
export var explorrBtn = "StopAnonymous-module--explorrBtn--29545";
export var finImg = "StopAnonymous-module--finImg--6887e";
export var heading = "StopAnonymous-module--heading--6a60c";
export var tabImg = "StopAnonymous-module--tabImg--2fd25";
export var tabbingCards = "StopAnonymous-module--tabbingCards--b0536";
export var tabs = "StopAnonymous-module--tabs--03175";