// extracted by mini-css-extract-plugin
export var Frame = "TechnologiesUse-module--Frame--fe299";
export var Rec = "TechnologiesUse-module--Rec--cb74b";
export var advance = "TechnologiesUse-module--advance--7868b";
export var cir = "TechnologiesUse-module--cir--7aa0c";
export var dots = "TechnologiesUse-module--dots--5c89b";
export var for1 = "TechnologiesUse-module--for1--d37df";
export var heading = "TechnologiesUse-module--heading--055ff";
export var iconssCard = "TechnologiesUse-module--iconssCard--65841";
export var techBg = "TechnologiesUse-module--techBg--32bc6";
export var techDot = "TechnologiesUse-module--techDot--aca11";
export var techIcon = "TechnologiesUse-module--techIcon--d80b7";
export var techImg = "TechnologiesUse-module--techImg--45c11";
export var technologyIcon = "TechnologiesUse-module--technologyIcon--b332f";