import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import arrowleft from "../../images/cyberSecurity/left.png"
import arrowright from "../../images/cyberSecurity/right.png"
import "./SimpleSteps.css"
import * as styles from "./SimpleSteps.module.scss"
import "./SimpleSteps.scss"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          src={arrowright}
          onClick={onClick}
          className={styles.portfolioArrowIcon}
          decoding="async"
          loading="lazy"
          alt="arrow"
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          src={arrowleft}
          className={styles.portfolioArrowIcon}
          decoding="async"
          loading="lazy"
          alt="arrow"
        />
      </div>
    </div>
  )
}

const SimpleSteps = ({ strapiData }) => {
  const [current, setCurrent] = useState(0)
  const [progress, setProgress] = useState(0)
  const [isPause, setIsPause] = useState(false)
  const [sliderIndex, setSliderIndex] = useState(0)
  const slide1 = useRef(null)
  useEffect(() => {
    const interval = setInterval(() => {
      isPause === false &&
        setProgress(progress => {
          if (current !== sliderIndex) {
            const nextSlide = () => {
              const nextIndex = sliderIndex + 1
              if (nextIndex >= 4) {
                return 0
              }
              return nextIndex
            }
            setProgress(0)
            setSliderIndex(nextSlide())
            slide1.current.slickGoTo(nextSlide())
          }
          const nextProgress = progress + 1
          const sliderProgress = document.querySelectorAll(`.isActive`)
          sliderProgress[0].style.width = `${progress}%`

          if (nextProgress > 100) {
            const nextSlide = () => {
              const nextIndex = sliderIndex + 1
              if (nextIndex >= 4) {
                return 0
              }
              return nextIndex
            }
            setProgress(0)
            setSliderIndex(nextSlide())
            slide1.current.slickGoTo(nextSlide())
            return 0
          }
          return nextProgress
        })
    }, 50)

    return () => clearInterval(interval)
  }, [progress, sliderIndex, isPause])

  const settings = {
    // dots: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    dotsClass: "button__bar",
    lazyLoad: "ondemand",
    beforeChange: (current, next) => setCurrent(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  return (
    <div className={`${styles.tabs} Building`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.Android}>
          <Row>
            <Col lg={12} xl={4}>
              <div variant="pills" className="progressBarContainer">
                {strapiData?.cards &&
                  strapiData?.cards?.map((e, i) => (
                    <div className={styles.SimpleSteps} key={i}>
                      <div className={styles.tabImg}>
                        <img
                          className={styles.finImg}
                          src={e?.image2 && e?.image2[0]?.localFile?.publicURL}
                          alt={e?.title}
                        />
                        <p className={styles.cardHeading}>{e?.title}</p>
                      </div>
                      <span>0{i + 1}</span>
                      <div
                        className={`progress-bar`}
                        id={`sliderProgress-${i}`}
                      >
                        <span
                          className={`${sliderIndex === i && "isActive"}`}
                        ></span>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
            <Col lg={12} xl={8}>
              <div className={styles.contentBlock}>
                <Slider
                  {...settings}
                  className={styles.SliderWidth}
                  ref={slide1}
                >
                  {strapiData?.cards &&
                    strapiData?.cards?.map((e, i) => (
                      <div
                        eventKey={`${e?.title}`}
                        key={i}
                        onMouseEnter={() => setIsPause(true)}
                        onMouseLeave={() => setIsPause(false)}
                      >
                        <img
                          className={styles.finImg}
                          src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                          alt={e?.title}
                        />
                        <div className={styles.boxContent}>
                          <h3>{e?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          ></p>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
export default SimpleSteps
