// extracted by mini-css-extract-plugin
export var bannerBtn = "HireCyber-module--bannerBtn--27e33";
export var bannerBtn2 = "HireCyber-module--bannerBtn2--3a521";
export var btn_white1_border = "HireCyber-module--btn_white1_border--1abfa";
export var cards = "HireCyber-module--cards--581ef";
export var description = "HireCyber-module--description--7c282";
export var hire = "HireCyber-module--hire--40101";
export var hireCyber = "HireCyber-module--hireCyber--3bc91";
export var hireImg = "HireCyber-module--hireImg--b0639";
export var talent = "HireCyber-module--talent--b8d6e";
export var text = "HireCyber-module--text--0b205";
export var tick = "HireCyber-module--tick--c6732";