// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--3215b";
export var clientDots2 = "Testimonials-module--clientDots2--32665";
export var content = "Testimonials-module--content--65ce7";
export var dots3 = "Testimonials-module--dots3--675e9";
export var hireArrow = "Testimonials-module--hireArrow--6418b";
export var iconContainer = "Testimonials-module--iconContainer--d7d94";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--ca81b";
export var imgBtn = "Testimonials-module--imgBtn--522b9";
export var person = "Testimonials-module--person--ef2a0";
export var personBg = "Testimonials-module--personBg--7a0ce";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--2654e";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--64da2";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--e7b68";
export var row = "Testimonials-module--row--b675a";
export var section = "Testimonials-module--section--ac7aa";
export var staffRec = "Testimonials-module--staffRec--7d3a7";
export var testName = "Testimonials-module--testName--ec7ce";
export var testimonials = "Testimonials-module--testimonials--9a4b9";
export var testimonialsContent = "Testimonials-module--testimonialsContent--15d94";
export var vector2 = "Testimonials-module--vector2--f8bb7";