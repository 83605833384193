import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/cyber-security/Banner"
import GotProject from "../components/cyber-security/GotProject"
import HireCyber from "../components/cyber-security/HireCyber"
import InvoZoneHelps from "../components/cyber-security/InvoZoneHelps"
import InvozoneNumber from "../components/cyber-security/InvozoneNumber"
import SimpleSteps from "../components/cyber-security/SimpleSteps"
import SimpleSteps2 from "../components/cyber-security/SimpleSteps2"
import StopAnonymous from "../components/cyber-security/StopAnonymous"
import StopAnonymous2 from "../components/cyber-security/StopAnonymous2"
import TechnologiesUse from "../components/cyber-security/TechnologiesUse"
import Testimonials from "../components/cyber-security/Testimonials"
import Testimonials2 from "../components/healthcare/Testimonials2"
import WorkPortfolio from "../components/common/WorkPortfolio"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/cyber_security_services_4a96733937.webp"
    />
  )
}
const cybersecurity = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const Anonymous = data?.strapiPage?.sections[1]
  const Goals = data?.strapiPage?.sections[2]
  const hire = data?.strapiPage?.sections[3]
  const number = data?.strapiPage?.sections[4]
  const form = data?.strapiPage?.sections[5]
  const simple = data?.strapiPage?.sections[6]
  const choose = data?.strapiPage?.sections[7]
  const tech = data?.strapiPage?.sections[8]
  const testimonials = data?.strapiPage?.sections[9]
  const faqs = data?.strapiPage?.sections[10]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      {!isMobile ? (
        <StopAnonymous strapiData={Anonymous} />
      ) : (
        <StopAnonymous2 strapiData={Anonymous} />
      )}
      <InvoZoneHelps strapiData={Goals} />
      <HireCyber strapiData={hire} />
      <InvozoneNumber strapiData={number} />
      <GotProject strapiData={form} pageName="Cyber Security Services" />
      {!isMobile ? (
        <SimpleSteps strapiData={simple} />
      ) : (
        <SimpleSteps2 strapiData={simple} />
      )}
      <EngagementModels strapiData={choose} />
      <TechnologiesUse strapiData={tech} />
      <WorkPortfolio
        portfolioHead={"Protecting Businesses Across Various Industries"}
        cyberWorkPortfolio={true}
      />
      {!isMobile ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}
      <Faqs strapiData={faqs} pageName="Cyber Security Services" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "cyber-security" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default cybersecurity
