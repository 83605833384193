import { Link } from "gatsby"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import Vector from "../../images/cyberSecurity/Vector.png"
import Vector1 from "../../images/cyberSecurity/Vector1.png"
import dots from "../../images/cyberSecurity/dots.png"
import Vector2 from "../../images/cyberSecurity/Vector2.png"
import Group from "../../images/cyberSecurity/Group.png"
import "./StopAnonymous.scss"
import * as styles from "./StopAnonymous.module.scss"

const StopAnonymous = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(strapiData?.cards[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className={`${styles.tabs} cardTabingCyber`}>
      <img
        src={Vector1}
        alt="right arrow"
        className={styles.Vector1}
        decoding="async"
        loading="lazy"
      />
      <img
        src={Vector}
        alt="right arrow"
        className={styles.Vector}
        decoding="async"
        loading="lazy"
      />
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <img
          src={dots}
          alt="right arrow"
          className={styles.dots}
          decoding="async"
          loading="lazy"
        />
        <div className={styles.Android}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="Penetration Testing"
          >
            <Row>
              <Col xl={7} lg={12}>
                <Nav variant="pills" className={`navbarAndroid`}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div className={styles.tabImg}>
                          <img
                            className={styles.finImg}
                            src={
                              e?.image1 && e?.image1[0]?.localFile?.publicURL
                            }
                            alt={e?.title}
                          />
                          <div
                            className={`tabLink ${
                              activeTab === e.title ? "active" : ""
                            }`}
                            onClick={() => handleTabClick(e.title)}
                          >
                            {e?.title}
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </Col>
              <Col
                xl={5}
                lg={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="tabs">
                  <Tab.Content className="contentBlock">
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <div
                          eventKey={`${e?.title}`}
                          key={i}
                          className={`tab-pane ${
                            activeTab === e.title ? "active" : ""
                          }`}
                        >
                          <div className={styles.boxContent}>
                            <div>
                              <img
                                className={styles.finImg}
                                src={
                                  e?.image1 &&
                                  e?.image1[0]?.localFile?.publicURL
                                }
                                alt={e?.title}
                              />
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <div className={`${styles.bannerBtn}`}>
          {strapiData?.buttons[0] && (
            <Link
              to={strapiData?.buttons[0]?.url}
              className={styles.btn_white1_border}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
        <img
          src={Vector2}
          alt="right arrow"
          className={styles.Vector2}
          decoding="async"
          loading="lazy"
        />
        <img
          src={Group}
          alt="right arrow"
          className={styles.Group}
          decoding="async"
          loading="lazy"
        />
      </Container>
    </div>
  )
}
export default StopAnonymous
