import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Vector3 from "../../images/cyberSecurity/Vector3.png"
import dots from "../../images/cyberSecurity/Group22.png"
import * as styles from "./StopAnonymous2.module.scss"

const StopAnonymous = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  useEffect(() => {
    current === strapiData?.cards.length - 1 &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current])

  // const settings = {
  //   autoplay: true,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   infinite: false,
  //   speed: 200,
  //   beforeChange: (current, next) => setCurrent(next),
  //   arrows: true,
  //   lazyLoad: "ondemand",
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // }

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <img
          src={Vector3}
          alt="right arrow"
          className={styles.Vector3}
          decoding="async"
          loading="lazy"
        />
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`gap-30 ${styles.cardWrapper}`}>
            <img
              src={dots}
              alt="right arrow"
              className={styles.dots}
              decoding="async"
              loading="lazy"
            />
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i < 7 && (
                    <Col xs={12} key={i}>
                      <div className={styles.card}>
                        <img
                          src={e?.image1[0]?.localFile?.publicURL}
                          alt={e?.title}
                          decoding="async"
                          loading="lazy"
                        />
                        <h3>{e?.title}</h3>
                        <p
                          className="mb-3"
                          dangerouslySetInnerHTML={{
                            __html: e?.subTitle,
                          }}
                        />
                      </div>
                    </Col>
                  )
              )}
            <div className={`${styles.bannerBtn}`}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className={styles.btn_white1_border}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StopAnonymous
