import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import "./SimpleSteps.scss"
import * as styles from "./SimpleSteps2.module.scss"

const settings = {
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: true,
  infinite: false,
  dotsClass: "cyber-dots",
  lazyLoad: "ondemand",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        dots: true,
      },
    },
  ],
}

const SimpleSteps = ({ strapiData }) => {
  return (
    <div className={`${styles.tabs} Building2`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.Android}>
          <Row>
            <Col sm={12}>
              <div className={styles.contentBlock}>
                <Slider {...settings} className={styles.SliderWidth}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map((e, i) => (
                      <div eventKey={`${e?.title}`} key={i}>
                        <img
                          className={styles.tabImg}
                          src={e?.image1 && e?.image1[1]?.localFile?.publicURL}
                          alt={e?.title}
                          decoding="async"
                          loading="lazy"
                        />
                        <div className={styles.boxContent}>
                          <img
                            className={styles.finImg}
                            src={
                              e?.image2 && e?.image2[0]?.localFile?.publicURL
                            }
                            alt={e?.title}
                            decoding="async"
                            loading="lazy"
                          />
                          <h3>{e?.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          ></p>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
export default SimpleSteps
