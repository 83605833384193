// extracted by mini-css-extract-plugin
export var SliderWidth = "InvoZoneHelps-module--SliderWidth--56fcb";
export var arrowImg = "InvoZoneHelps-module--arrowImg--d4037";
export var card = "InvoZoneHelps-module--card--e814e";
export var cardWrapper = "InvoZoneHelps-module--cardWrapper--e87ed";
export var cards = "InvoZoneHelps-module--cards--24b35";
export var description = "InvoZoneHelps-module--description--84afb";
export var fineBg = "InvoZoneHelps-module--fineBg--b1043";
export var heading = "InvoZoneHelps-module--heading--810b6";
export var headingPremium = "InvoZoneHelps-module--headingPremium--08931";
export var premiumBg = "InvoZoneHelps-module--premiumBg--54b9b";
export var premiumImg = "InvoZoneHelps-module--premiumImg--0228b";
export var providingImg = "InvoZoneHelps-module--providingImg--fba73";
export var teamButton = "InvoZoneHelps-module--teamButton--f7f7d";
export var teamWork = "InvoZoneHelps-module--teamWork--51943";
export var trailBg = "InvoZoneHelps-module--trailBg--e76ce";