// extracted by mini-css-extract-plugin
export var SliderWidth = "StopAnonymous2-module--SliderWidth--b5a3e";
export var Vector3 = "StopAnonymous2-module--Vector3--fa1a7";
export var arrowImg = "StopAnonymous2-module--arrowImg--26e4e";
export var bannerBtn = "StopAnonymous2-module--bannerBtn--87c80";
export var btn_white1_border = "StopAnonymous2-module--btn_white1_border--c2b5d";
export var card = "StopAnonymous2-module--card--f103e";
export var cardWrapper = "StopAnonymous2-module--cardWrapper--17cdc";
export var cards = "StopAnonymous2-module--cards--790f0";
export var description = "StopAnonymous2-module--description--23a34";
export var dots = "StopAnonymous2-module--dots--5f2de";
export var fineBg = "StopAnonymous2-module--fineBg--c23de";
export var heading = "StopAnonymous2-module--heading--be170";
export var headingPremium = "StopAnonymous2-module--headingPremium--3b90c";
export var premiumBg = "StopAnonymous2-module--premiumBg--0616a";
export var premiumImg = "StopAnonymous2-module--premiumImg--6c98b";
export var providingImg = "StopAnonymous2-module--providingImg--3966f";
export var teamButton = "StopAnonymous2-module--teamButton--9d126";
export var teamWork = "StopAnonymous2-module--teamWork--4ae9e";