import React from "react"
import * as styles from "./TechnologiesUse.module.scss"

const Technologies = ({ strapiData }) => {
  return (
    <div className={styles.advance}>
      <div className={styles.techBg}>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }} />
        <div className={styles.techIcon}>
          {strapiData?.cards &&
            strapiData?.cards?.map((el, i) => (
              <div className={`${styles.iconssCard}`} key={i}>
                <div className={styles.technologyIcon}>
                  <div className={styles.techImg}>
                    <div>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={el?.image1[0]?.localFile?.publicURL}
                        alt={el?.title}
                      />
                    </div>
                  </div>
                  <h3>{el?.title}</h3>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Technologies
