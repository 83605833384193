// extracted by mini-css-extract-plugin
export var BannerImage = "Banner-module--BannerImage--d0628";
export var BannerImage2 = "Banner-module--BannerImage2--938f7";
export var banner = "Banner-module--banner--4c4a3";
export var bannerBtn = "Banner-module--bannerBtn--f86ca";
export var bannerHeading = "Banner-module--bannerHeading--cdea6";
export var bannerPadding = "Banner-module--bannerPadding--e9f04";
export var bannerUl = "Banner-module--bannerUl--14342";
export var btn_white1_border = "Banner-module--btn_white1_border--fdda2";
export var btn_white2_border = "Banner-module--btn_white2_border--9fe57";
export var heading = "Banner-module--heading--c748c";