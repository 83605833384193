// extracted by mini-css-extract-plugin
export var Android = "SimpleSteps2-module--Android--73d0e";
export var SimpleSteps = "SimpleSteps2-module--SimpleSteps--39f0e";
export var SliderWidth = "SimpleSteps2-module--SliderWidth--1976a";
export var arrowImg = "SimpleSteps2-module--arrowImg--cf0fc";
export var border = "SimpleSteps2-module--border--588cf";
export var boxContent = "SimpleSteps2-module--boxContent--95392";
export var cards = "SimpleSteps2-module--cards--8406b";
export var cardsContent = "SimpleSteps2-module--cardsContent--cf8d7";
export var cardsZ = "SimpleSteps2-module--cardsZ--6a06d";
export var contentBlock = "SimpleSteps2-module--contentBlock--37c69";
export var description = "SimpleSteps2-module--description--519e7";
export var estabBtn = "SimpleSteps2-module--estabBtn--b0e41";
export var example = "SimpleSteps2-module--example--325ce";
export var explorrBtn = "SimpleSteps2-module--explorrBtn--c478c";
export var finImg = "SimpleSteps2-module--finImg--d860c";
export var heading = "SimpleSteps2-module--heading--bceac";
export var tabImg = "SimpleSteps2-module--tabImg--3e623";
export var tabbingCards = "SimpleSteps2-module--tabbingCards--b547f";
export var tabs = "SimpleSteps2-module--tabs--d3e10";