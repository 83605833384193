import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData, breadCrumb }) => {
  return (
    <div className={styles.banner}>
      <Container>
        <nav className="mb-4">
          <Link to={`/`} className="text-white">
            Home
          </Link>
          {" » "}
          {breadCrumb?.map((val, i) =>
            val?.url !== "#" ? (
              <React.Fragment key={i}>
                <Link to={`/${val?.url}/`} className="text-white">
                  {val?.title}
                </Link>
                {" » "}
              </React.Fragment>
            ) : (
              <span className="text-white">{val?.title}</span>
            )
          )}
        </nav>
        <Row>
          <Col lg={6} md={12}>
            <h1 className={`${styles.bannerBtn} pb-2`}>
              {strapiData?.buttons[0] && (
                <Link className={styles.btn_white1_border}>
                  Cybersecurity Service Provider
                </Link>
              )}
            </h1>
            <div className={styles.heading}>
              <p
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={`${styles.bannerBtn}`}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className={styles.btn_white2_border}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col lg={6} md={12} className={styles.bannerPadding}>
            <img
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt={"cyber_security_service_providing_company"}
              decoding="async"
              loading="lazy"
              className={`${styles.BannerImage} `}
            />
            <img
              src={strapiData?.secImages[1]?.localFile?.publicURL}
              alt={"cyber_security_service_providing_company small"}
              decoding="async"
              loading="lazy"
              className={`${styles.BannerImage2} `}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
