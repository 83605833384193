// extracted by mini-css-extract-plugin
export var Android = "SimpleSteps-module--Android--60216";
export var SimpleSteps = "SimpleSteps-module--SimpleSteps--75389";
export var SliderWidth = "SimpleSteps-module--SliderWidth--e55c7";
export var arrowImg = "SimpleSteps-module--arrowImg--ccea1";
export var border = "SimpleSteps-module--border--25194";
export var boxContent = "SimpleSteps-module--boxContent--09310";
export var cardHeading = "SimpleSteps-module--cardHeading--7264e";
export var cards = "SimpleSteps-module--cards--fa4f6";
export var cardsContent = "SimpleSteps-module--cardsContent--234e1";
export var cardsZ = "SimpleSteps-module--cardsZ--2a965";
export var contentBlock = "SimpleSteps-module--contentBlock--08a77";
export var description = "SimpleSteps-module--description--f8f67";
export var estabBtn = "SimpleSteps-module--estabBtn--f75c8";
export var example = "SimpleSteps-module--example--d5930";
export var explorrBtn = "SimpleSteps-module--explorrBtn--b0b92";
export var finImg = "SimpleSteps-module--finImg--10794";
export var heading = "SimpleSteps-module--heading--ce627";
export var iconContainer = "SimpleSteps-module--iconContainer--f2076";
export var iconContainerLeft = "SimpleSteps-module--iconContainerLeft--e140c";
export var portfolioArrowIcon = "SimpleSteps-module--portfolioArrowIcon--00352";
export var portfolioArrowIconCover = "SimpleSteps-module--portfolioArrowIconCover--64183";
export var portfolioArrowRightIconCover = "SimpleSteps-module--portfolioArrowRightIconCover--5d746";
export var tabImg = "SimpleSteps-module--tabImg--7a388";
export var tabbingCards = "SimpleSteps-module--tabbingCards--ca167";
export var tabs = "SimpleSteps-module--tabs--4a2a2";